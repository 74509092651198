import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AnalysisExecutions from './pages/AnalysisExecutions'
import L1Prompts from './pages/VideoAnalysisPrompts'
import L1Explorer from './pages/L1Explorer'
import L2Explorer from './pages/L2Explorer'
import ReportPreview from './pages/ReportPreview'
import SecondBrain from './pages/SecondBrain'
import SecondBrainQC from './pages/SecondBrainQC'
import Sidebar from './components/Sidebar'
import PrivateRoutes from './pages/Login/PrivateRoutes'
import Login from './pages/Login'
import ListProcessedVideos from './pages/ListProcessedVideos'
import TemplateList from './pages/Report Builder/main'
import UpdateTemplate from './pages/Report Builder/updateTemplate'
import AddTemplate from './pages/Report Builder/addTemplate'
import DuplicateTemplate from './pages/Report Builder/duplicateTemplate'
import DetailedExecutionAnalysis from './pages/DetailedExecutionAnalysis'
import PostHogPageviewTracker from './components/PageViewTrack/PageViewTracker'
import Channel from './pages/Detailed Info/channel'
import Video from './pages/Detailed Info/video'
import AnalyzeChannel from './pages/AnalyzeChannel'
import VideoBuilder from './pages/VideoBuilder'
import Worker from './pages/Worker'
import WorkerRunPreview from './pages/WorkerRunPreview'
import WorkerChainBuilder from './pages/WorkerChainBuilder'
import WorkerChainRunPreview from './pages/WorkerChainRunPreview'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_ADMIN_ROLE, AUTH0_CUSTOMER_ADMIN_ROLE, AUTH0_FIXATED_ROLE } from './constants'

import './App.css'

const MainLayout: React.FC = () => {
	const location = useLocation()
	const hideSidebarRoutes = ['/', '/claude']
	const hideSidebarRoutePrefixes = [/^\/report\//g, /worker\/(?!runs)/g, /worker-chain\/(?!runs)/g, /workflow/g]

	const shouldHideSidebar =
		hideSidebarRoutes.includes(location.pathname) ||
		hideSidebarRoutePrefixes.filter((routeRegex) => routeRegex.test(location.pathname)).length > 0

	return (
		<div className='App ml-2 d-flex'>
			<PostHogPageviewTracker />

			{!shouldHideSidebar && <Sidebar />}

			<div className='content flex-grow-1'>
				<Routes>
					<Route path='/' element={<Login />} />

					{/* Public route for workflow run preview */}
					<Route
						path='/workflow/:workerChainId/run/:runId/version/:versionId'
						element={<WorkerChainRunPreview />}
					/>

					{/* Routes accessible to all users */}
					<Route element={<PrivateRoutes />}>
						<Route path='/home' element={<ListProcessedVideos />} />
						<Route path='/process-channel' element={<AnalyzeChannel />} />
						<Route path='/channel-details/:channel_id' element={<Channel />} />
						<Route path='/channel-details/:channel_id/:video_id' element={<Video />} />

						<Route path='/analysis-executions' element={<AnalysisExecutions />} />
						<Route path='/analysis/:analysisId' element={<DetailedExecutionAnalysis />} />

						<Route path='/video-builder' element={<VideoBuilder />} />

						<Route path='/workers' element={<Worker />} />
						<Route path='/workflow' element={<WorkerChainBuilder />} />

						<Route path='/report/:channelId/:templateId' element={<ReportPreview />} />
						<Route path='/report/:channelId/:templateId/:requestedAt' element={<ReportPreview />} />

						<Route path='/worker/:workerId/run/:runId' element={<WorkerRunPreview />} />
						<Route path='/worker/:workerId/run/:runId/version/:versionId' element={<WorkerRunPreview />} />

						<Route path='/claude' element={<L2Explorer useContext={false} />} />

						<Route path='*' element={<div>Page not found.</div>} />
					</Route>

					{/* Routes accessible to admin and customer admins */}
					<Route element={<PrivateRoutes allowedRoles={[AUTH0_ADMIN_ROLE, AUTH0_CUSTOMER_ADMIN_ROLE, AUTH0_FIXATED_ROLE]} />}>
						<Route path='/l1-explorer' element={<L1Explorer />} />
						<Route path='/l2-explorer' element={<L2Explorer />} />
					</Route>

					{/* Routes accessible to admin only */}
					<Route element={<PrivateRoutes allowedRoles={[AUTH0_ADMIN_ROLE]} />}>
						<Route path='/second-brain' element={<SecondBrain />} />
						<Route path='/qc-prompts' element={<SecondBrainQC />} />
						<Route path='/l1-prompts' element={<L1Prompts />} />

						<Route path='/view-template' element={<TemplateList />} />
						<Route path='/update-template' element={<UpdateTemplate />} />
						<Route path='/add-template' element={<AddTemplate />} />
						<Route path='/duplicate-template' element={<DuplicateTemplate />} />
					</Route>
				</Routes>
			</div>
		</div>
	)
}

function App() {
	return (
		<Auth0Provider
			domain={AUTH0_DOMAIN}
			clientId={AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: `https://${AUTH0_DOMAIN}/api/v2/`,
				scope: 'openid profile email',
			}}>
			<Router>
				<MainLayout />
			</Router>
			<ToastContainer />
		</Auth0Provider>
	)
}

export default App
