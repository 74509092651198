import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import useAuthenticateUser from './authenticateUser'
import { AUTH0_ROLE_NAMESPACE } from '../../constants'
import { AUTH0_FIXATED_ROLE } from '../../constants'

const PrivateRoutes: React.FC<{ allowedRoles?: string[] }> = ({ allowedRoles = [] }) => {
	const userAuthenticated = useAuthenticateUser()

	const { user } = useAuth0()
	const roles: string[] = user?.[AUTH0_ROLE_NAMESPACE] || []
	
	// Check if user email ends with @fixated.co and allowedRoles contains the exact fixated role
	const hasFixatedEmail = user?.email?.endsWith('@fixated.co') || false
	const hasFixatedRole = allowedRoles.includes(AUTH0_FIXATED_ROLE)
	
	// User is authorized if they have the required roles OR they have a fixated email and the roles include the exact fixated role
	const userAuthorized = 
		allowedRoles.length === 0 || 
		allowedRoles.some((role) => roles.includes(role)) || 
		(hasFixatedEmail && hasFixatedRole)

	if (userAuthenticated === null || userAuthenticated === undefined) {
		return <div>Loading...</div>
	}

	if (!userAuthenticated) {
		return <Navigate to='/' />
	}

	if (!userAuthorized) {
		return <div>Access Denied</div>
	}

	return <Outlet />
}

export default PrivateRoutes
